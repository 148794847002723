.container
{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 0 1rem;
}

.marginTop{
    margin-top: 2.5rem;
}

.marginBottom{
    margin-bottom: 2.5rem;
}

.container.minHeight
{
    min-height: 85dvh;
}

.header
{
    width: 100%;
    text-align: center;
    font-size: 2rem;
    margin-bottom: 4.5rem;
    margin-top: 3rem;
    padding: 0 1rem 2rem; 
    text-transform: uppercase;
    border-bottom: 2px solid var(--color-separator);
    position: relative;
    color: var(--color-primary-text);
}

.header::before
{
    content: "";
    position: absolute;
    bottom: -1.2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 2px;
    background-color: var(--color-separator);
    z-index: 1;
}

@media screen and (min-width:576px)
{
    .container
    {
        width: 540px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (min-width:768px)
{
    .container
    {
        width: 720px;
    }

    .header
    {
        font-size: 2.4rem;
    }
}

@media screen and (min-width:992px)
{
    .container
    {
        width: 960px;
    }
    
}

@media screen and (min-width:1200px)
{
    .container
    {
        width: 1140px;
    }
}

@media screen and (min-width:1400px)
{
    .container
    {
        width: 1320px;
    }

    .marginTop{
        margin-top: 3rem;
    }

    .marginBottom{
        margin-bottom: 3rem;
    }
}
