.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: white;
  padding-bottom: 1rem;
  -webkit-box-shadow: 0px 0px 8px 1px rgb(161, 161, 161, 0.4);
  -moz-box-shadow: 0px 0px 8px 1px rgb(161, 161, 161, 0.4);
  box-shadow: 0px 0px 8px 1px rgb(161, 161, 161, 0.4);
  min-height: 70dvh;
  border-radius: 5px;
}

.link{
    width: 100%;
    padding: 1.5rem 0.2rem;
    text-align: center;
    transition: background-color 0.3s ease-out;
    text-decoration: none;
    color: black;
    text-transform: uppercase;
    font-weight: 500;
}

.link:hover{
    background-color: rgb(242, 242, 242);
}

.link.active{
    background-color: rgb(242, 242, 242);
    -webkit-box-shadow: inset 0px 0px 8px 1px rgb(161, 161, 161, 0.15);
    -moz-box-shadow: inset 0px 0px 8px 1px rgb(161, 161, 161, 0.15);
    box-shadow: inset 0px 0px 8px 1px rgb(161, 161, 161, 0.15);
}