.header {
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    padding: 0 1rem;
    font-weight: 700;
    text-transform: uppercase;
    color: var(--color-primary-text);
  }
  
  @media screen and (min-width: 769px) {
    .header {
      font-size: 1.8rem;
    }
  }