* {
  font-family: sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}

html {
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: url("./images/MainPageSlider/wave.png"),
    linear-gradient(
      180deg,
      var(--color-background-secondary) 50%,
      var(--color-background) 50%
    );
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: 50%;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 3px;
}

body::-webkit-scrollbar-thumb {
  background: rgb(160, 160, 160);
  border-radius: 3px;
}

body::-webkit-scrollbar-thumb:hover {
  background: rgb(112, 112, 112);
}

p {
  margin-bottom: 1rem;
}

/* @media and () */
