.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 0.6rem;
  transition: transform 0.3s ease-out, color 0.3s ease-out;
}

.container:hover {
  color: rgb(93, 37, 0);
  transform: scale(1.01) translateX(0.1rem);
}
