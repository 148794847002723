.container
{
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.number
{
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10rem;
    font-weight: bold;
    color: rgb(227, 227, 227);
}

.text
{
    font-size: 2rem;
    color: var(--color-primary-text);
    font-weight: bold;
    position: relative;
    z-index: 1;
    text-align: center;
}

@media screen and (min-width:769px) {
    .number
    {
        font-size: 15rem;
    }

    .text
    {
        font-size: 2.5rem;
    }
}