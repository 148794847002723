.container {
  position: relative;
}

.container::before {
  position: absolute;
  inset: 0;
  background-color: rgba(51, 30, 11, 0.25);
  z-index: 5;
  content: " ";
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
  font-size: 4.5rem;
  font-weight: 500;
  width: 100%;
  font-family: "Inter", "Roboto", sans-serif;
}

.linkContainer {
  position: absolute;
  z-index: 20;
  width: 100%;
  bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

@media screen and (min-width:750px) {
  .linkContainer {
    position: absolute;
    z-index: 20;
    width: 100%;
    bottom: 5rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
  }
}