.map {
  width: 100%;
  height: 300px;
  -webkit-box-shadow: 0px 0px 30px -15px rgba(169, 169, 169, 1);
  -moz-box-shadow: 0px 0px 30px -15px rgba(169, 169, 169, 1);
  box-shadow: 0px 0px 30px -15px rgba(169, 169, 169, 1);
  margin-bottom: 2rem;
}

@media screen and (min-width: 769px) {
  .map {
    width: 50%;
    height: 350px;
  }
}

@media screen and (min-width: 1201px) {
  .map {
    width: 50%;
    height: 400px;
  }
}
