.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
}

.header {
  font-size: 2.2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.8rem;
  width: 100%;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  gap: 0.8rem;
  max-width: 100%;
  width: 30rem;
  background-color: white;
  padding: 2.5rem;
  -webkit-box-shadow: 0px 0px 8px 1px rgb(161, 161, 161, 0.7);
  -moz-box-shadow: 0px 0px 8px 1px rgb(161, 161, 161, 0.7);
  box-shadow: 0px 0px 8px 1px rgb(161, 161, 161, 0.7);
}

@media screen and (min-width: 769px) {
  .header {
    font-size: 2.4rem;
  }
}
