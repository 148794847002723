.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;
    position: relative;
  }
  
  .title {
    width: 100%;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color: var(--color-primary-text);
  }
  
  .formGroup {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .imagesGroup{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .imagesErrors{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    color: red;
    align-items: center;
  }