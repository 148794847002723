.cardContainer {
  width: 100%;
  position: relative;
  transition:transform 0.3s ease-out;
  cursor: pointer;
}

.cardContainer:not(.disableScaling):hover{
  transform: scale(1.01);
}

.card{
  width: 100%;
  overflow: hidden;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 0.5rem;
  background-color: white;
  position: relative;
}

.cardContainer::before{
  content: "";
  border-radius: 15px;
  position: absolute;
  inset: 0;
  opacity: 0.3;
  -webkit-box-shadow: 0px 0px 8px 1px rgb(161, 161, 161);
  -moz-box-shadow: 0px 0px 8px 1px rgb(161, 161, 161);
  box-shadow: 0px 0px 8px 1px rgb(161, 161, 161);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}

.cardContainer:hover::before{
  opacity: 0.7;
}

.link{
  position: absolute;
  inset: 0;
}

.imageContainer {
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;
  flex-shrink: 0;
  position: relative;
}

.imageContainer img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
  display: flex;
  transition: opacity 0.3s ease-out;
  opacity: 0;
}

.imagePreview{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(6px);
  display: flex;
  left: 0;
  top: 0;
}

.body {
  flex-grow: 1;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  min-height: 7rem;
}

.itemsHeader {
  font-size: 1.3rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.category {
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.category,
.text {
  font-size: 0.9rem;
  color: rgb(88, 88, 88);
}

.text{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (min-width: 576px) {
  .card {
    flex-direction: row;
    gap: 0.8rem;
  }

  .body{
    min-height: auto;
  }

  .imageContainer {
    width: 30%;
    max-width: 180px;
  }
  
  .itemsHeader.padding{
    padding-right: 4.5rem;
  }
}

@media screen and (min-width:992px){
  
  .itemsHeader{
    font-size: 1.5rem;
  }

  .body {
    gap: 0.5rem;
    padding: 0.8rem;
  }
  
}
