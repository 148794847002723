.container
{
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text
{
    text-align: center;
    font-size: 1.3rem;
    color: var(--color-primary-text);
}

@media screen and (min-width:769px)
{
    .text
    {
        font-size: 1.5rem;
    }
}