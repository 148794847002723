
.contactInfoContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  padding: 0 0.5rem;
}

.link {
  text-decoration: none;
  color: unset;
}

.link .data {
  cursor: pointer;
}

.data {
  font-size: 1.3rem;
  cursor: default;
}

@media screen and (min-width: 769px) {

  .contactInfoContainer {
    width: 50%;
    padding-right: 1rem;
  }

  .data {
    font-size: 1.2rem;
  }
}
