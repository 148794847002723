.backdrop
{
    position: fixed;
    left: 0;
    top: 0;
    display: none;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.2s linear;
    backdrop-filter: blur( 1px );
}

.backdrop.display
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.backdrop.display.visible
{
    opacity: 1;
}

@media screen and (min-width: 769px) {
    
    .backdrop.display.menu
    {
        display: none;
    }
}