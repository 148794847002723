.navbar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 5.5rem;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  padding: 0 0.8rem;
  background-color: var(--color-primary);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(7.5px);
  z-index: 10;
  transition: all 0.3s ease-out;
}


.navbar.scrolled
{
    height: 4.5rem;
    background-color: var(--color-secondary);
    backdrop-filter: blur(7.5px);
    -webkit-backdrop-filter: blur(7.5px);
}

.navbar a:not(.logo),
.navbar a:hover:not(.logo),
.navbar a:visited:not(.logo),
.navbar span,
.tagger
{
  display: none;
}

.logo{
  font-size: 2rem;
  color: white;
  text-decoration: none;
  margin-left: auto;
  display: block;
  font-family: "Inter", "Roboto", sans-serif;
}

.hamburger
{
    margin-right: auto;
    position: relative;
    width: 2rem;
    height: 1.6rem;
    border-top: 3px white solid;
    transition: all ease-out 0.3s;
}

.hamburger::after,
.hamburger::before
{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    content: "";
    border-top: 3px solid rgb(255, 255, 255);
    transition: all ease-out 0.2s;
}

.hamburger::after
{
    transform: translateY(-0.7rem);
}

.hamburger.unfolded
{
    border-top: none;
}

.unfolded.hamburger::after
{
    transform: translateY(-0.8rem) rotate(45deg);
}

.unfolded.hamburger::before
{
    transform: translateY(-0.8rem) rotate(-45deg);
}

@media screen and (min-width: 769px) {
  .navbar a:not(.logo),
  .navbar a:hover:not(.logo),
  .navbar a:visited:not(.logo),
  .navbar span
  {
    display: block;
    color: rgb(255, 255, 255);
    font-size: 1.1rem;
    font-weight: bold;
    text-decoration: none;
    padding: 0.6rem;
    text-align: center;
  }

  .logo{
    margin-left: 0;
    margin-right: auto;
  }
  
  .hamburger
  {
      display: none;
  }

  .linksContainer {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    position: relative;
  }

  .linksContainer a{
    white-space: nowrap;
  }

  .tagger {
    display: block;
    position: absolute;
    height: 0.1rem;
    left: 0;
    bottom: 0;
    background-color: rgb(245, 245, 245);
    z-index: -1;
    transition: margin-left 0.3s ease-out, width 0.3s ease-out;
  }

  .navbar span
  {
    cursor: pointer;
  }

}
