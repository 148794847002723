.linkWrapper:nth-child(1) {
  animation: buttonHorizontalFadeIn 0.5s ease-out 0s 1 forwards;
}

.linkWrapper:nth-child(2) {
  animation: buttonHorizontalFadeIn 0.5s ease-out 0.15s 1 forwards;
}

.linkWrapper:nth-child(3) {
  animation: buttonHorizontalFadeIn 0.5s ease-out 0.3s 1 forwards;
}

.linkWrapper {
  transform: translateX(-3rem);
  opacity: 0;
}

.link {
  text-decoration: none;
  padding: 0.8rem 0.6rem;
  color: var(--color-primary);
  text-transform: uppercase;
  font-size: 1.2rem;
  background-color: white;
  border-radius: 10px;
  min-width: 14rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  -webkit-box-shadow: 0px 6px 22px -6px rgb(52, 33, 8);
  -moz-box-shadow: 0px 6px 22px -6px rgb(52, 33, 8);
  box-shadow: 0px 6px 22px -6px rgb(52, 33, 8);
  transition: transform 0.25s ease-out;
}

.link:hover {
  transform: scale(1.025) translateY(-0.15rem);
  background-color: var(--color-primary);
  color: white;
}

.link svg {
  transition: transform 0.25s ease-out;
}

.link:hover svg {
  transform: translateX(0.25rem);
}

@keyframes buttonVerticalFadeIn {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes buttonHorizontalFadeIn {
  to {
    transform: translateX(0);
    opacity: 1;
  }
}


@media screen and (min-width:750px) {
  .link {
    padding: 1rem 0.8rem;
    font-size: 1.4rem;
    min-width: 11rem;
  }

  .linkWrapper:nth-child(1) {
    animation: buttonVerticalFadeIn 0.8s ease-out 0s 1 forwards;
  }
  
  .linkWrapper:nth-child(2) {
    animation: buttonVerticalFadeIn 0.8s ease-out 0.15s 1 forwards;
  }
  
  .linkWrapper:nth-child(3) {
    animation: buttonVerticalFadeIn 0.8s ease-out 0.3s 1 forwards;
  }
  
  .linkWrapper {
    transform: translateY(2rem);
    opacity: 0;
  }
}