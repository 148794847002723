.container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  aspect-ratio: 16/9;
  width: 100%;
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-position: center;
  object-fit: cover;
}

@media screen and (min-width: 426px) {
  .container {
    width: calc(50% - (1rem / 2));
  }
}

@media screen and (min-width: 992px) {
  .container {
    width: calc(33.33% - (2rem / 3));
  }
}
